import React, { useEffect } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Banner from "./Banner";
import { useNavigate } from "react-router-dom";
export default function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    const userType = localStorage.getItem("user_type");
    if (userType === "SALES") {
      navigate("/dashboardSales");
    } else if (userType === "RETAILER") {
      navigate("/dashboard_retailer");
    } else if (userType === "HO") {
      navigate("/dashboardAdmin");
    } else if (userType == "FA") {
      navigate("/dashboardFinance");
    }
  }, []);
  return (
    <>
      <Header />
      <Banner />
      <Footer />
    </>
  );
}
