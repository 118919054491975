import React from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
export default function CamTranReport() {
    return (
        <>
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><a href="#Section1" role="tab" data-toggle="tab">Campaign Transaction Report</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                {/* <div class="row clearfix mb-15 callwell">
                                    <div class="col-md-3 col-sm-12 col-xs-8">
                                        <input className="frmcntrl" placeholder="Name/Mobile no." type="text" value={searchUser} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(date) => setFromDate(date)}
                                            dateFormat="dd-MM-yyyy"
                                            className="frmcntrl"
                                            id="Fromdate"
                                            showYearDropdown
                                            showMonthDropdown
                                            autoComplete="off"
                                            scrollableYearDropdown
                                            maxDate={new Date()}
                                            onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                            onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                            onClick={(e) => e.preventDefault()} // Preven
                                            placeholderText='From date'
                                        />
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <DatePicker
                                            selected={toDate}
                                            onChange={(date) => setToDate(date)}
                                            dateFormat="dd-MM-yyyy"
                                            className="frmcntrl"
                                            id="todate"
                                            showYearDropdown
                                            showMonthDropdown
                                            autoComplete="off"
                                            scrollableYearDropdown
                                            maxDate={new Date()}
                                            onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                            onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                            onClick={(e) => e.preventDefault()} // Preven
                                            placeholderText='To date'
                                        />
                                    </div>

                                    <div class="col-md-3 col-sm-3 col-xs-4">
                                        <button type="button" class="btn login_white_btn"
                                            onClick={() => kycPendingReport(searchUser)}> Search <img src={longarrow} alt='' /></button>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="carrd1">
                                            <div className="card-body table-responsive">
                                                <table className="table table-bordered mytable mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Zone</th>
                                                            <th>ZSM Name</th>
                                                            <th>ZSM Mobile Number</th>
                                                            <th>Zone State</th>
                                                            <th>Zone Area Name	</th>
                                                            <th>RSM Name</th>
                                                            <th>RSM Mobile Number</th>
                                                            <th>ASM Name</th>
                                                            <th>ASM Mobile Number</th>
                                                            <th>TSE Name</th>
                                                            <th>TSE Mobile Number</th>
                                                            <th>Plumber Name</th>
                                                            <th>Plumber Unique ID* (Mobile Number)</th>
                                                            <th>Date of Registration</th>
                                                            <th>City</th>
                                                            <th>District</th>
                                                            <th>State</th>
                                                            <th>Pin Code</th>
                                                            <th>Campaign Name</th>
                                                            <th>Campaign Start Date</th>
                                                            <th>Campaign End Date</th>
                                                            <th>Points Earned</th>
                                                            <th>Current Points</th>
                                                            <th>Total Points</th>
                                                            <th>Points Credited</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="12" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            {/* <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td> */}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
