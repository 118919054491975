import React from 'react'
import banner1 from '../../../assets/img/slider/banner1.jpg'
import banner2 from '../../../assets/img/slider/banner2.jpg'
import banner3 from '../../../assets/img/slider/banner3.jpg'
import banner4 from '../../../assets/img/slider/banner4.jpg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
export default function Banner() {
    return (
        <>
            <div id="banner" className="carousel slide mt134" data-ride="carousel">
                <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
                    <div>
                        <img src={banner1} alt='' />
                    </div>
                    <div>
                        <img src={banner2} alt='' />
                    </div>
                    <div>
                        <img src={banner3} alt='' />
                    </div>
                </Carousel>
            </div>
        </>
    )
}
